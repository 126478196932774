<template>
  <div>

    <Navbar
    ></Navbar> 

    <GenericHeader :title="$t('view.public.header')"></GenericHeader>
    <PublicTree></PublicTree>


  </div>
</template>

<script>

import Navbar from '@/components/Navbar.vue'
import GenericHeader from '@/components/GenericHeader.vue'
import PublicTree from '@/components/PublicTree.vue';

import { mapState, mapActions } from 'vuex'

export default {
  title() {
    return this.$t('view.public.name')
  },
  components: {
    Navbar,
    GenericHeader,
    PublicTree,
  },
  methods: {
    ...mapActions([
      'getUsers',
    ]),
  },
  computed: {
    ...mapState({
      isDarkTheme: (state) => state.settings.isDarkTheme,
    }),
  },
  beforeRouteEnter (to, from, next) {
    console.log('beforeRouteEnter fired on Public page');
    next( vm => {
      vm.getUsers();
    });
  }
}

</script>